
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userprofileApi from "@/core/services/UserProfile";
import buttonHandle from "@/shared.js";

interface UserTypeInterface {
    id: number;
    label: string;
    position: number;
    services: Array<string>;
}

export default defineComponent({
    name: "administration-access",
    data: function () {
        return {
            userTypes: [
                {
                    id: 0,
                    label: "",
                    position: 0,
                    services: [],
                } as UserTypeInterface,
            ],
        };
    },
    methods: {
        getUserTypes() {
            userprofileApi.getUserTypes().then((response) => {
                let userTypes = response.data.usertypes;
                this.userTypes = userTypes.map((userType) => {
                    userType.services = userType.services.map((service) => {
                        return service.slug;
                    });
                    return userType;
                });
            });
        },
        switchServices(service, usertypeId) {
            this.userTypes = this.userTypes.map((usertype) => {
                if (usertype.id === usertypeId) {
                    if (usertype.services.indexOf(service) !== -1) {
                        usertype.services.splice(usertype.services.indexOf(service), 1);
                    } else {
                        usertype.services.push(service);
                    }
                }
                return usertype;
            });
        },
        updateUserType(usertype) {
            if (usertype.position === 2) {
                buttonHandle.handleWaitingButton(this.submitButton2);
                setTimeout(() => {
                    buttonHandle.handleValidatedButton(this.submitButton2);
                    userprofileApi.updateUserTypes(this.userTypes);
                }, 500);
            } else if (usertype.position === 3) {
                buttonHandle.handleWaitingButton(this.submitButton3);
                setTimeout(() => {
                    buttonHandle.handleValidatedButton(this.submitButton3);
                    userprofileApi.updateUserTypes(this.userTypes);
                }, 500);
            }
        },
    },
    created() {
        this.getUserTypes();
    },
    setup() {
        const submitButton2 = ref<HTMLElement | null>(null);
        const submitButton3 = ref<HTMLElement | null>(null);
        onMounted(() => {
            setCurrentPageBreadcrumbs("Gestion des accès", []);
        });
        return { submitButton2, submitButton3 };
    },
});
